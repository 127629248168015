.white {
  color: white;
}

.r {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 117px;
}

* {
  box-sizing: border-box;
}
bod {
  background-color: #d2dbdc;
  margin: 0;
  font-family: segoe Ul, Arial, Helvetica, "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", "Geneva", Verdana, sans-serif;
}
.app-window {
  display: flex;
  height: 100vh;
  background-color: #ededed;
}
.slidebar {
  width: 35%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dfff;
}

header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  /* flex-direction: ; */
}
.header--avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}
.header--button {
  display: flex;
}
.cobtainerarea {
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
